<template>
  <b-card
    class="application-item"
    :class="application.is_locked_status ? 'border locked-status-border' : ''"
    @contextmenu.prevent="$refs.menu.open"
  >
    <b-form-checkbox
      v-model="markedElements"
      :value="application.id"
      class="apliction-checkbox"
      inline
      @click.stop
    />
    <b-link
      :to="{ name: 'admin-application-edit', params: { id: application.id, type: getApplicationUserType(application) } }"
      class="w-100 d-flex flex-wrap align-items-center"
    >
      <div
        v-for="(column, index) in selectedViewColumns"
        :key="index"
      >
        <b-badge
          v-if="!['selected', 'actions', 'tags', 'has_payment_failed'].includes(column.name) && application[column.name]"
          pill
          :style="{
            backgroundColor: requirementBackgroundColor(column.name),
            color: requirementTextColor(column.name),
            whiteSpace: 'normal'
          }"
          class="mb-1 mr-1 text-break"
        >{{ column.label }}: {{ checkValue(application[column.name], column.name) }}
        </b-badge>

        <badge-tag-list
          v-if="column.name === 'tags'"
          :tag-names="filterTags(application[column.name])"
        />

        <div
          v-if="column.name === 'has_payment_failed'"
          class="mb-1"
        >
          <b-badge
            v-if="application[column.name]"
            variant="danger"
          >
            Payment Failed
          </b-badge>
        </div>
      </div>
    </b-link>

    <!-- context -->
    <vue-context
      ref="menu"
    >
      <li
        class="text-center cursor-pointer"
        @click.prevent="showEditStatusModal()"
      >
        <span>Move to Status</span>
      </li>
    </vue-context>

  </b-card>
</template>

<script>
import VueContext from 'vue-context'
import {
  BCard,
  BBadge,
  BLink,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { filters } from '@core/mixins/filters'
import store from '@/store'
import BadgeTagList from '@/views/components/badge-tag-list/BadgeTagList.vue'

export default {
  name: 'ApplicationItem',
  components: {
    BCard,
    BBadge,
    BLink,
    BFormCheckbox,
    BadgeTagList,
    VueContext,
  },
  mixins: [filters],
  props: {
    application: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      selectedViewColumns: 'app-applications/getSelectedViewColumns',
      selectedViewTagSets: 'app-applications/getSelectedViewTags',
    }),
    markedElements: {
      get() {
        return store.getters['app-applications/getMarkedElements']
      },
      set(val) {
        store.commit('app-applications/SET_MARKED_ELEMENTS', val)
      },
    },
  },
  methods: {
    requirementBackgroundColor(key) {
      if (key === 'user_name') return '#E5FBFE'
      if (key === 'age') return '#F6E2F5'
      if (key === 'group_names') return '#EEE4FC'
      if (key === 'session_names') return '#f6c1c1'
      if (key === 'user_role') return '#F8F1E0'
      if (key === 'balance') return '#E5E0F9'
      if (key === 'last_step_number') return '#EDFCE0'
      if (key === 'program') return '#E2F3F3'
      if (key === 'created_at') return '#f8dedf'

      if (key === 'birth_date') return '#F9EAE0'
      if (key === 'status') return '#dad7d7'
      return '#c0c0c0'
    },
    requirementTextColor(key) {
      if (key === 'user_name') return '#0FA8BA'
      if (key === 'age') return '#BD1CB7'
      if (key === 'group_names') return '#862FF4'
      if (key === 'session_names') return '#bd1919'
      if (key === 'user_role') return '#E6A029'
      if (key === 'balance') return '#4918CF'
      if (key === 'last_step_number') return '#63CC0A'
      if (key === 'program') return '#329797'
      if (key === 'created_at') return '#8f1a43'
      if (key === 'birth_date') return '#E76721'
      if (key === 'status') return '#5b5b5b'
      return '#000'
    },
    checkValue(value, name) {
      if (name === 'created_at') {
        return this.$options.filters.longDate(value)
      }

      return value
    },
    getApplicationUserType(application) {
      return application.user_role === 'Staff' ? application.user_role : this.camperStudent(1)
    },
    filterTags(tags) {
      const availableViewTags = this.selectedViewTagSets.map(tagSet => tagSet.id)

      if (availableViewTags.length > 0) {
        return tags
          .filter(tag => availableViewTags.includes(tag.tag_set_id))
      }
      return tags
    },
    showEditStatusModal() {
      store.commit('app-applications/ADD_ELEMENT', this.application.id)
      this.$bvModal.show('bulk-change-status')
    },
  },
}
</script>

<style lang="scss" scoped>
.application-item {
  background: #FFFFFF;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding-left: 1rem;
}
.application-item:last-child {
  margin-bottom: 0;
}
.apliction-checkbox {
  position: absolute;
  top: 1.5rem;
  left: 0.7rem;
}
.requirement-color {
  position: absolute;
  left: 0;
  height: 80%;
  width: 4px;
  border-radius: 4px;
  background: #000;
}
.locked-status-border {
  border: 2px solid #EA5455 !important;
}
</style>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-context.scss';
</style>
