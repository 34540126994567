import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { sessionSemester } from '@/helpers/instanceNamingConvention'
import router from '@/router'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useApplicationsList(root) {
  // Use toast
  const toast = useToast()

  const refApplicationsListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    {
      key: 'selected', label: 'selected', name: 'selected', selected: true, sortable: false,
    },
    {
      key: 'user_name', label: 'Full Name', name: 'user_name', selected: true, sortable: true, class: 'name-col',
    },
    {
      key: 'user_first_name', label: 'First Name', name: 'user_first_name', selected: true, sortable: true,
    },
    {
      key: 'user_last_name', label: 'Last Name', name: 'user_last_name', selected: true, sortable: true,
    },
    {
      key: 'age', label: 'Age', name: 'age', selected: true, sortable: true,
    },
    {
      key: 'grade', label: 'Grade', name: 'grade', selected: true, sortable: true,
    },
    {
      key: 'balance', label: 'Balance', name: 'balance', selected: true, sortable: true,
    },
    {
      key: 'ifp_balance', label: 'Running Balance (IFN)', name: 'ifp_balance', selected: true, sortable: true,
    },
    {
      key: 'last_step_number', label: 'Last Step Number', name: 'last_step_number', selected: true, sortable: true,
    },
    {
      key: 'status', label: 'Status', name: 'status', selected: true, sortable: true,
    },
    {
      key: 'created_at', label: 'Created at', name: 'created_at', selected: true, sortable: true,
    },
    {
      key: 'semesters', label: `${sessionSemester}s`, name: 'session_names', selected: true, sortable: true,
    },
    {
      key: 'dob', label: 'DoB', name: 'birth_date', selected: true, sortable: true,
    },
    // {
    //   key: 'groups', label: 'Groups', name: 'group_names', selected: true, sortable: true,
    // },
    {
      key: 'has_payment_failed', label: 'Payment info', name: 'has_payment_failed', selected: true, sortable: true,
    },
    {
      key: 'tags', label: 'Tags', name: 'tags', selected: true, sortable: true,
    },
    {
      key: 'actions', label: 'Actions', name: 'actions', selected: true,
    },
  ]

  const tableItems = ref([])
  const isTableLoading = ref(false)
  const defaultPerPage = Number(localStorage.getItem('applicationsPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const filterUserTypeOptions = ['Student', 'Staff']
  const searchQuery = ref('')
  const filterDataUserType = ref('')
  const filterDataStatus = ref('')
  const filterDataPaymentStatus = ref(null)
  const filterDataStep = computed(() => store.getters['app-applications/getFilterDataStep'])
  const filterDataGrade = computed(() => store.getters['app-applications/getFilterDataGrade'])
  const filterDataTags = ref('')
  let sortBy = ref(null)
  let isSortDirDesc = ref(null)
  const sortByFromStorage = computed(() => store.getters['app-applications/getStorageSortBy'])
  const isSortDirDescFromStorage = computed(() => store.getters['app-applications/getStorageIsSortByDesc'])
  const statusFilter = ref(null)
  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })
  let debounceTimeout = ref(null)

  const clearFilters = () => {
    filterDataUserType.value = ''
    filterDataStatus.value = ''
    filterDataPaymentStatus.value = ''
    store.commit('app-applications/SET_FILTER_DATA_STEP', '')
    store.commit('app-applications/SET_FILTER_DATA_GRADE', '')
  }

  const refetchData = () => {
    // eslint-disable-next-line no-use-before-define
    refApplicationsListTable.value.refresh()
  }

  const updateSortingData = value => {
    sortBy = ref(value)
  }
  const updateSortingByDescData = value => {
    isSortDirDesc = ref(value)
  }

  watch([currentPage, perPage, searchQuery, filterDataUserType, filterDataStatus, filterDataPaymentStatus, filterDataStep, filterDataGrade, sortByFromStorage, isSortDirDescFromStorage],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataUserTypeVal, filterDataStatusVal, filterDataPaymentStatusVal, filterDataStepVal, filterDataGradeVal, sortByFromStorageVal, isSortDirDescFromStorageVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataUserTypePrevVal, filterDataStatusPrevVal, filterDataPaymentStatusPrevVal, filterDataStepPrevVal, filterDataGradePrevVal, sortByFromStoragePrevVal, isSortDirDescFromStoragePrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-applications', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-applications', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-applications', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataUserTypeVal !== filterDataUserTypePrevVal) {
        pushStateFiltersParams(root, 'admin-applications', 'filterDataUserType', filterDataUserTypeVal)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-applications', 'filterDataStatus', filterDataStatusVal)
      }
      if (filterDataPaymentStatusVal !== filterDataPaymentStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-applications', 'filterDataPaymentStatus', filterDataPaymentStatusVal)
      }
      if (filterDataStepVal !== filterDataStepPrevVal) {
        pushStateFiltersParams(root, 'admin-applications', 'filterDataStep', filterDataStepVal)
      }
      if (filterDataGradeVal !== filterDataGradePrevVal) {
        pushStateFiltersParams(root, 'admin-applications', 'filterDataGrade', filterDataGradeVal)
      }
      if (sortByFromStorageVal !== sortByFromStoragePrevVal) {
        updateSortingData(sortByFromStorageVal)
      }
      if (isSortDirDescFromStorageVal !== isSortDirDescFromStoragePrevVal) {
        updateSortingByDescData(isSortDirDescFromStorageVal)
      }
    })

  const fetchApplicationList = (ctx, callback) => {
    if (!ctx || !callback) {
      return
    }

    const sortByValue = sortBy.value ? sortBy.value : sortByFromStorage.value
    const isSortDirDescValue = isSortDirDesc.value !== null ? isSortDirDesc.value : isSortDirDescFromStorage.value

    isTableLoading.value = true
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortByValue ?? 'user_name',
      sortDesc: isSortDirDescValue,
      withoutDraft: true,
      programId: programId.value,
    }

    const userRole = router.currentRoute.params?.type
    if (userRole) {
      payloadData.userRole = userRole === 'Staff' ? userRole : 'Student'
    }
    if (filterDataStatus.value) payloadData.statusId = filterDataStatus.value
    if (filterDataPaymentStatus.value) payloadData.paymentStatus = filterDataPaymentStatus.value
    if (searchQuery.value) payloadData.userName = searchQuery.value
    if (filterDataTags.value) payloadData.tagId = filterDataTags.value
    if (filterDataStep.value) payloadData.application_step_id = filterDataStep.value
    if (filterDataGrade.value) payloadData.grade = filterDataGrade.value
    store
      .dispatch('app-applications/fetchApplicationList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        tableItems.value = data
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching applications' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isTableLoading.value = false
      })
  }

  const fetchApplicationsByStatus = queryParams => axios
    .get('/auth/v2/applications', {
      params: queryParams,
    })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching applications' list",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const deleteItem = ID => axios
    .delete(`auth/applications/${ID}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting application',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting application',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const updateApplicationStatuses = queryParams => axios
    .post('/auth/bulk-application-statuses', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Statuses was successfully changed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error change Application Statuses',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateApplicationsStepStatus = queryParams => axios
    .post('auth/applications-step-result/bulk-change-status', queryParams)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error changing Applications Step Status',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateApplicationStatusById = (ID, queryParams) => axios
    .post(`/auth/application-status/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Status was successfully changed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error change Application Status',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const exportApplicationToPdf = id => axios
    .get(`/auth/applications/${id}/export-to-pdf`, { responseType: 'blob' })
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error exporting pdf',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const bulkExportFamilyReport = queryParams => axios
    .get('/auth/applications/bulk-export/family-report', {
      params: queryParams,
      responseType: 'blob',
    })
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error exporting pdf',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'rejected') return 'danger'
    return 'primary'
  }

  const actionOptions = [
    { value: 'view', title: 'View', link: 'admin-application-edit' },
  ]

  return {
    fetchApplicationList,
    programId,
    tableColumns,
    tableItems,
    isTableLoading,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    filterUserTypeOptions,
    searchQuery,
    filterDataUserType,
    filterDataStatus,
    filterDataPaymentStatus,
    filterDataStep,
    filterDataGrade,
    filterDataTags,
    sortBy,
    totalItems,
    isSortDirDesc,
    refApplicationsListTable,

    statusFilter,
    actionOptions,

    resolveStatusVariant,

    refetchData,
    clearFilters,
    deleteItem,

    updateApplicationStatuses,
    fetchApplicationsByStatus,
    updateApplicationStatusById,
    updateApplicationsStepStatus,
    exportApplicationToPdf,
    bulkExportFamilyReport,
  }
}
