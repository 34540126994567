<template>
  <div>
    <b-card
      no-body
    >
      <!-- Header-->
      <div
        class="header d-flex align-items-center justify-content-end my-2"
      >
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <label class="mr-1 mb-0">Search</label>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search"
              class="w-25"
            />
            <view-customizer />
          </div>
        </b-col>
      </div>

      <Spinner
        v-if="isLoading"
      />

      <div
        v-else
      >
        <div
          class="statuses-list"
        >
          <status-card
            v-for="status in statusesList"
            :key="status.id"
            :status-name="status.name"
            :status-id="status.id"
          />
        </div>
      </div>

      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end p-2 pr-3"
        >
          <b-dropdown
            id="dropdown-buttons"
            text="Bulk Actions"
            variant="outline-danger"
          >
            <b-dropdown-item
              v-if="$can('add', permissionSubjects.GroupMember)"
              v-b-modal.bulk-add-to-group-modal
              :disabled="markedElements.length < 1"
            >
              Bulk Add To Group
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canSendBulkCommunication"
              v-b-modal.bulk-communication-modal
              :disabled="markedElements.length < 1"
            >
              Bulk Communication
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('update', permissionSubjects.ChildApplicationStatus)"
              v-b-modal.bulk-change-status
              :disabled="markedElements.length < 1"
            >
              Bulk Change Status
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('create', permissionSubjects.Charge)"
              v-b-modal.bulk-adding-charge-modal
              :disabled="markedElements.length < 1"
            >
              Bulk Charge
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.bulk-generate-letter-modal
              :disabled="markedElements.length < 1"
            >
              Bulk Generate Letter
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('assign', permissionSubjects.Tags)"
              v-b-modal.bulk-tag-assign-modal
              :disabled="markedElements.length < 1"
            >
              Bulk Tag Assign
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('approve' || 'reject', permissionSubjects.ChildApplicationStep)"
              v-b-modal.bulk-change-step-status
              :disabled="markedElements.length < 1 || !filterDataStep"
            >
              Bulk Approve/Reject Step
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

      </b-row>

    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BFormInput, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import store from '@/store'
import StatusCard
  from '@/views/admin/applications/applications-list/applications-kanban-view/application-status-card/StatusCard.vue'
import ViewCustomizer from '@/views/components/applications-viewing-options/ViewCustomizer.vue'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { permissionSubjects } from '@/libs/acl/constants'

let debounceTimeout = null

export default {
  name: 'ApplicationsKanbanView',
  components: {
    BCard,
    BFormInput,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,

    StatusCard,
    ViewCustomizer,
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      selectedViewColumns: 'app-applications/getSelectedViewColumns',
      statusesList: 'app-applications/getStatusesList',
      forceReload: 'app-applications/getForceReload',
      markedElements: 'app-applications/getMarkedElements',
      selectedViewTagsselectedViewTags: 'app-applications/getSelectedViewTags',
      filterDataStep: 'app-applications/getFilterDataStep',
    }),
    searchQuery: {
      get() {
        return store.getters['app-applications/getSearchQuery']
      },
      set(val) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          store.commit('app-applications/SET_SEARCH_QUERY', val)
        }, 500)
      },
    },
    canSendBulkEmailCommunication() {
      return this.$can('send', permissionSubjects.BulkEmailCommunication)
    },
    canSendBulkSmsCommunication() {
      return this.$can('send', permissionSubjects.BulkSMSCommunication)
    },
    canSendBulkCommunication() {
      return this.canSendBulkEmailCommunication || this.canSendBulkSmsCommunication
    },
  },
  watch: {
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.$route.name, 'searchQuery', val)
      }
    },
  },
  beforeDestroy() {
    store.commit('app-applications/RESET_ALL_APPLICATIONS_BY_STATUSES')
  },
}
</script>

<style lang="scss" scopped>
.statuses-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 400px;
}
.new-status {
  align-self: start;
  background: #FBFBFC;
  border: 1px solid #D8D6DE;
  border-radius: 8px;
  width: 270px;
  flex-basis: 270px;
  flex-shrink: 0;
  margin: 0 10px;
}
</style>
