<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h2 class="mb-0">
        Applications
      </h2>
      <b-button
        variant="outline-primary"
        :to="{ name: 'admin-import-applications'}"
        :disabled="!$can('create', permissionSubjects.ChildApplication) || !$can('create', permissionSubjects.StaffApplication)"
      >
        <span>Import Applications</span>
      </b-button>
    </div>
    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="true"
            label="name"
            :disabled="boardViewType === 'Kanban'"
            :reduce="option => option.id"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataPaymentStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterPaymentOptions"
            :clearable="true"
            :reduce="option => option.value"
            :placeholder="'Payment Filter'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataStep"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStepsOptions"
            :clearable="true"
            label="name"
            :reduce="option => option.id"
            :placeholder="'Select Step'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataGrade"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterGradeOptions"
            :clearable="true"
            :placeholder="'Select Grade'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center mt-1">
            <b-button
              variant="primary"
              @click="clearAllFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

    </b-card>

    <applications-kanban-view
      v-if="boardViewType ==='Kanban'"
    />

    <applications-table-view
      v-else
    />

    <confirmation-modal
      v-if="deletableApplication"
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="`Are you sure you want to permanently delete this application(${deletableApplication.user_name})?`"
      :is-loading="isAppDeleteLoading"
      @close-modal="confirmationModalHide = !confirmationModalHide"
      @confirm="deleteApplication"
    />

    <generate-letter-modal
      v-if="generateLetterForApplication"
      :user-full-name="generateLetterForApplication.user_name"
      :user-id="generateLetterForApplication.user_id"
      :program-id="generateLetterForApplication.program_id"
      :type="getApplicationUserType(generateLetterForApplication.user_role)"
    />

    <!-- Bulk Change Status -->
    <application-bulk-change-status-modal
      :is-loading="isLoading"
      :statuses-list="filterStatusOptions"
      :elements-count="markedElements.length"
      @selectStatus="showConfirmUpdatingStatusManual"
    />

    <bulk-communicate-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :total-elements-count="markedElements.length"
      :filter-name="'application_id'"
      @successfullySend="bulkCommunicateSuccess"
    />

    <bulk-add-to-group-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      @successfullySend="bulkAddToGroupSuccess"
    />

    <bulk-adding-charge-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      @success="bulkCreateChargeSuccess"
    />

    <bulk-generate-letter-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      @success="bulkGenerateLetterSuccess"
    />

    <bulk-tag-assign-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      :program-id="programId"
      @success="bulkTagAssignSuccess"
    />

    <confirmation-modal
      :toggle-modal="confirmationChangeStatusModalHide"
      :type="'-application-locked-status'"
      :title="'Are you sure'"
      :message="'The manual status change will exclude this application from the automated status change based on status completion. ' +
        'You can do the same by marking the steps as completed or rejected. Would you like to proceed?'"
      @close-modal="confirmationChangeStatusModalHide = false"
      @confirm="confirmUpdatingStatusManual"
    />

    <!-- Bulk Change Step Status -->
    <application-bulk-change-step-status-modal
      :elements-count="markedElements.length"
      @changeStatus="changeApplicationsStepStatus"
    />

    <info-modal
      :modal-name="infoModalName"
      :title="''"
      :message="'You\'ll receive the email notification as soon as the process is completed'"
    />

    <!-- Bulk Export Family Reports -->
    <export-pdf-modal
      :is-loading="isPdfLoading"
      :title="'Export Family Reports'"
      @handleExportPdf="exportFamilyReports"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BButton, VBModal,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import moment from 'moment'
import store from '@/store'
import storeModule from '@/views/admin/applications/applicationsStoreModule'
import useApplicationsList from '@/views/admin/applications/applications-list/useApplicationsList'

import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import GenerateLetterModal from '@/views/components/generate-letter-modal/GenerateLetterModal.vue'
import ApplicationBulkChangeStatusModal from '@/views/components/application/ApplicationBulkChangeStatusModal.vue'
import BulkCommunicateModal from '@/views/components/bulk-communicate-modal/BulkCommunicateModal.vue'
import BulkAddToGroupModal from '@/views/components/bulk-add-to-group/BulkAddToGroupModal.vue'
import BulkAddingChargeModal from '@/views/components/adding-charge-modal/BulkAddingChargeModal.vue'
import BulkGenerateLetterModal from '@/views/components/bulk-generate-letter/BulkGenerateLetterModal.vue'
import BulkTagAssignModal from '@/views/components/bulk-tag-assign/BulkTagAssignModal.vue'
import ApplicationsKanbanView from '@/views/admin/applications/applications-list/applications-kanban-view/ApplicationsKanbanView.vue'
import ApplicationsTableView
  from '@/views/admin/applications/applications-list/applications-table-view/ApplicationsTableView.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import ApplicationBulkChangeStepStatusModal from '@/views/components/application/ApplicationBulkChangeStepStatusModal.vue'
import InfoModal from '@/views/components/info-modal/InfoModal.vue'
import ExportPdfModal from '@/views/components/fafsa-export-pdf-modal/ExportPdfModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'ApplicationsList',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,

    vSelect,
    ConfirmationModal,
    GenerateLetterModal,
    ApplicationBulkChangeStatusModal,
    BulkCommunicateModal,
    BulkAddToGroupModal,
    BulkAddingChargeModal,
    BulkGenerateLetterModal,
    BulkTagAssignModal,
    ApplicationsKanbanView,
    ApplicationsTableView,
    ApplicationBulkChangeStepStatusModal,
    InfoModal,
    ExportPdfModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      filterStatusOptions: [],
      filterPaymentOptions: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Payment Failed',
          value: 'failed',
        },
        {
          label: 'Not Payment Failed',
          value: 'not_failed',
        },
        {
          label: 'Payment Pending',
          value: 'pending',
        },
      ],
      confirmationModalHide: false,
      columns: [],
      elementsPerPageCheckbox: false,
      isLoading: false,
      confirmationChangeStatusModalHide: false,
      newStatus: null,
      infoModalName: 'bulkChangeStepStatus',
      isAppDeleteLoading: false,
      isPdfLoading: false,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-applications'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      programId,
      perPage,
      perPageOptions,
      clearFilters,
      deleteItem,
      updateApplicationStatuses,
      updateApplicationsStepStatus,
      bulkExportFamilyReport,
    } = useApplicationsList(root)

    return {
      tableColumns,
      programId,
      perPage,
      perPageOptions,
      clearFilters,
      deleteItem,
      updateApplicationStatuses,
      updateApplicationsStepStatus,
      bulkExportFamilyReport,
    }
  },
  computed: {
    ...mapGetters({
      boardViewType: 'app-applications/getBoardViewType',
      storageTableColumns: 'app-applications/getStorageTableColumns',
      deletableApplication: 'app-applications/getDeletableApplication',
      forceReload: 'app-applications/getForceReload',
      generateLetterForApplication: 'app-applications/getGenerateLetterForApplication',
      filterStepsOptions: 'app-applications/getStepsList',
      filterGradeOptions: 'app-applications/getGradesList',
    }),
    markedElements: {
      get() {
        return store.getters['app-applications/getMarkedElements']
      },
      set(val) {
        store.commit('app-applications/SET_MARKED_ELEMENTS', val)
      },
    },
    filterDataStatus: {
      get() {
        return store.getters['app-applications/getFilterDataStatus']
      },
      set(val) {
        store.commit('app-applications/SET_FILTER_DATA_STATUS', val)
      },
    },
    filterDataPaymentStatus: {
      get() {
        return store.getters['app-applications/getFilterDataPaymentStatus']
      },
      set(val) {
        store.commit('app-applications/SET_FILTER_DATA_PAYMENT_STATUS', val)
      },
    },
    filterDataStep: {
      get() {
        return store.getters['app-applications/getFilterDataStep']
      },
      set(val) {
        store.commit('app-applications/SET_FILTER_DATA_STEP', val)
      },
    },
    filterDataGrade: {
      get() {
        return store.getters['app-applications/getFilterDataGrade']
      },
      set(val) {
        store.commit('app-applications/SET_FILTER_DATA_GRADE', val)
      },
    },
  },
  watch: {
    programId() {
      this.prepareStatuses()
      this.prepareStepsList()
      this.prepareGradesList()
    },
    filterDataStatus(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.$route.name, 'filterDataStatus', val)
      }
    },
    filterDataPaymentStatus(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.$route.name, 'filterDataPaymentStatus', val)
      }
    },
    filterDataStep(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.$route.name, 'filterDataStep', val)
      }
    },
    boardViewType() {
      store.commit('app-applications/SET_FILTER_DATA_STATUS', '')
    },
  },
  async created() {
    this.setApplicationBoardViewOptions()
    this.prepareStatuses()
    this.prepareStepsList()
    this.prepareGradesList()
  },
  mounted() {
    this.setFilterParams()
  },
  beforeDestroy() {
    store.commit('app-applications/SET_STATUSES_LIST', [])
  },
  methods: {
    async prepareStatuses() {
      const filterData = {
        program_id: this.programId,
        sortBy: 'position',
      }

      const userRole = this.$route.params?.type
      if (userRole) {
        filterData.type = userRole === 'Staff' ? userRole : 'Student'
      }
      await this.$store.dispatch('app-applications/fetchApplicationStatuses', filterData)
        .then(response => {
          this.filterStatusOptions = response.data.data
          store.commit('app-applications/SET_STATUSES_LIST', response.data.data)
        })
    },
    refreshData() {
      store.commit('app-applications/SET_FORCE_RELOAD', !this.forceReload)
    },
    getApplicationUserType(userRole) {
      return userRole === 'Staff' ? userRole : this.camperStudent(1)
    },
    async deleteApplication() {
      if (this.deletableApplication.id) {
        try {
          this.isAppDeleteLoading = true
          await this.deleteItem(this.deletableApplication.id)
          store.commit('app-applications/SET_DELETABLE_APPLICATION', null)
          this.refreshData()
        } finally {
          this.isAppDeleteLoading = false
        }
      }

      this.confirmationModalHide = !this.confirmationModalHide
    },
    setApplicationBoardViewOptions() {
      const localStorageColumns = this.storageTableColumns
      // Check if added new table columns
      if (localStorageColumns.length > 0) {
        const localStorageColumnsKeys = localStorageColumns.map(col => col.key)
        const tableColumnsKeys = this.tableColumns.map(col => col.key)
        const differentColumns = tableColumnsKeys.filter(key => !localStorageColumnsKeys.includes(key)).concat(localStorageColumnsKeys.filter(key => !tableColumnsKeys.includes(key)))

        const localStorageColumnsNames = localStorageColumns.map(col => col.name)
        const tableColumnsNames = this.tableColumns.map(col => col.name)
        const differentColumnsNames = tableColumnsNames.filter(name => !localStorageColumnsNames.includes(name)).concat(localStorageColumnsNames.filter(name => !tableColumnsNames.includes(name)))

        const localStorageColumnsLabels = localStorageColumns.map(col => col.label)
        const tableColumnsLabels = this.tableColumns.map(col => col.label)
        const differentColumnsLabels = tableColumnsLabels.filter(label => !localStorageColumnsLabels.includes(label)).concat(localStorageColumnsLabels.filter(label => !tableColumnsLabels.includes(label)))

        if (differentColumns.length > 0 || differentColumnsNames.length > 0 || differentColumnsLabels.length > 0) {
          this.columns = JSON.parse(JSON.stringify(this.tableColumns))
          store.commit('app-applications/SET_SELECTED_VIEW_COLUMNS', this.columns)
        } else {
          this.columns = localStorageColumns
        }
        return
      }
      this.columns = JSON.parse(JSON.stringify(this.tableColumns))
      store.commit('app-applications/SET_SELECTED_VIEW_COLUMNS', this.columns)
    },
    async showConfirmUpdatingStatusManual(newStatus) {
      this.newStatus = newStatus
      this.confirmationChangeStatusModalHide = true
    },
    async confirmUpdatingStatusManual() {
      this.confirmationChangeStatusModalHide = false
      await this.updApplicationStatuses(this.newStatus)
    },
    async updApplicationStatuses(status, withoutCharging = null) {
      this.isLoading = true
      const queryParams = {
        status_id: status.id,
        without_charging: !!withoutCharging,
        ids: this.markedElements,
      }
      await this.updateApplicationStatuses(queryParams)
        .then(() => {
          this.markedElements = []
          this.refreshData()
        })
        .finally(() => {
          this.$bvModal.hide('bulk-change-status')
          this.isLoading = false
          this.newStatus = null
        })
    },
    async bulkCommunicateSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-communication-modal')
      this.refreshData()
    },
    async bulkAddToGroupSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-add-to-group-modal')
      this.refreshData()
    },
    async bulkCreateChargeSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-adding-charge-modal')
      this.refreshData()
    },
    async bulkGenerateLetterSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-generate-letter-modal')
    },
    async bulkTagAssignSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-tag-assign-modal')
      this.refreshData()
    },
    clearAllFilters() {
      store.commit('app-applications/CLEAR_FILTERS')
      this.clearFilters()
    },
    setFilterParams() {
      store.commit('app-applications/SET_FILTER_PARAMS')
    },
    async prepareStepsList() {
      const queryParams = {
        program_id: this.programId,
      }

      const userRole = this.$route.params?.type
      if (userRole) {
        queryParams.type = userRole === 'Staff' ? userRole : 'Student'
      }
      const response = await this.$store.dispatch('app-applications/fetchStepsList', queryParams)
      store.commit('app-applications/SET_STEPS_LIST', response.data.data)
    },
    async prepareGradesList() {
      const queryParams = {
        program_id: this.programId,
      }

      const response = await this.$store.dispatch('app-applications/fetchGradesList', queryParams)
      store.commit('app-applications/SET_GRADES_LIST', response.data.data)
    },
    changeApplicationsStepStatus(status) {
      const queryParams = {
        status,
        application_step_id: this.filterDataStep,
        application_ids: this.markedElements,
      }
      this.updateApplicationsStepStatus(queryParams)
      this.$bvModal.show(`info${this.infoModalName}`)
      this.markedElements = []
    },
    async exportFamilyReports(isSinglePdfFile, templateId) {
      this.isPdfLoading = true
      const queryParams = {
        application_ids: this.markedElements,
        is_single_pdf_file: isSinglePdfFile,
        template_id: templateId,
      }
      await this.bulkExportFamilyReport(queryParams)
        .then(response => {
          if (response.status === 200) {
            this.downloadFile(response.data, `Family Reports-${moment().format('YYYY-MM-DD')}`, 'zip')
          }
        })
        .finally(() => {
          this.markedElements = []
          this.$bvModal.hide('export-pdf-modal')
          this.isPdfLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
