<template>
  <div
    class="mb-2"
  >
    <validation-observer
      ref="creditCard"
    >
      <b-card
        class="pt-1"
        bg-variant="light"
        border-variant="secondary"
      >
        <b-row>
          <b-col
            sm="12"
          >
            <b-form-group
              label="Credit Card Number"
            >
              <validation-provider
                #default="{ errors }"
                name="Credit Card Number"
                rules="cardNumber|required"
                mode="eager"
              >
                <b-form-input
                  v-model="card.number"
                  v-mask="['#### #### #### ####','#### #### #### ###']"
                  autocomplete="cc-number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
          >
            <b-form-group
              label="Expiry Date"
            >
              <validation-provider
                #default="{ errors }"
                name="Expiry Date"
                rules="cardDate|required"
                mode="eager"
              >
                <b-form-input
                  v-model="card.date"
                  v-mask="'##/##'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
          >
            <b-form-group
              label="CVC/CVV"
            >
              <validation-provider
                #default="{ errors }"
                name="CVC/CVV"
                rules="required|min:3"
                mode="eager"
              >
                <b-form-input
                  v-model="card.cvc"
                  v-mask="['###', '####']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col
          sm="6"
        >
          <b-button
            variant="outline-primary"
            class="w-100"
            @click="cancel"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col
          sm="6"
        >
          <b-button
            variant="primary"
            class="w-100"
            @click="saveCard"
          >
            Save Card
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { isValid, isExpirationDateValid } from 'creditcard.js'

extend('cardNumber', value => isValid(value))
extend('cardDate', value => isExpirationDateValid(value.split('/')[0], value.split('/')[1]))

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      card: {
        number: '',
        date: '',
        cvc: '',
      },
      required,
      min,
    }
  },
  methods: {
    resetCardData() {
      this.card = {
        number: '',
        date: '',
        cvc: '',
      }
    },
    cancel() {
      this.$emit('cancel')
      this.resetCardData()
    },
    async saveCard() {
      this.$refs.creditCard.validate().then(success => {
        if (success) {
          const queryParams = {
            number: this.card.number,
            exp_month: this.card.date.split('/')[0],
            exp_year: this.card.date.split('/')[1],
            cvc: this.card.cvc,
          }
          this.$emit('addNewCard', queryParams)
        }
      })
    },
  },
}
</script>
