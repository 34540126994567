<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="0.3"
      rounded="sm"
    >
      <b-card
        class="status-card"
        body-class="p-0"
      >
        <template #header>
          <div class="w-100 d-flex align-items-center justify-content-between border-bottom pb-1">
            <h5 class="mb-0 d-flex align-items-center">
              <b-form-checkbox
                v-model="selectedAllStatusApplications"
                :disabled="applications.length == 0"
                @change="markElementsOnStatus"
              />
              <span>{{ statusName }}</span>
            </h5>
          </div>
        </template>

        <b-card-body
          ref="statusCardBody"
          class="status-card-body"
        >
          <span
            v-if="currentPageOnRefresh > 1 && !hasError"
            class="load-prev text-primary"
            @click="fetchPrevApplications"
          >Load previous</span>

          <draggable
            v-if="!hasError"
            :id="statusId"
            :value="applications"
            :group="{ name: 'status', value: statusId }"
            :move="checkMove"
            :disabled="!$can('update', permissionSubjects.ChildApplicationData)"
            style="height: 100%"
            @add="onAdd"
            @remove="onRemove"
            @end="dispatchStatusChanged"
          >
            <ApplicationItem
              v-for="(application, index) in applications"
              :id="application.id"
              :key="index"
              :status="statusName"
              :application="application"
            />
          </draggable>

          <div v-if="hasError">
            <div class="text-danger text-center">
              Error fetching applications' list
            </div>
            <div
              class="load-prev text-primary"
              @click="refetchApplications"
            >
              Refresh Applications
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>

    <confirmation-modal
      :toggle-modal="confirmationModalHide"
      :type="'-application-locked-status'"
      :title="'Are you sure'"
      :message="'The manual status change will exclude this application from the automated status change based on status completion. ' +
        'You can do the same by marking the steps as completed or rejected. Would you like to proceed?'"
      :is-loading="isChangeStatusLoading"
      @close-modal="discardChanges"
      @confirm="saveChosenStatus"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BCardBody,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'

import ApplicationItem
  from '@/views/admin/applications/applications-list/applications-kanban-view/application-status-card/ApplicationItem.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import useApplicationsList from '@/views/admin/applications/applications-list/useApplicationsList'

import store from '@/store'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'StatusCard',
  components: {
    BCard,
    BCardBody,
    BFormCheckbox,
    BOverlay,

    draggable,
    ApplicationItem,
    ConfirmationModal,
  },
  props: {
    statusName: {
      type: String,
      required: true,
    },
    statusId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timer: null,
      isLoading: false,
      applications: [],
      perPage: 25,
      page: 1,
      currentPageOnRefresh: 1,
      lastPage: 1,
      newStatusId: null,
      draggedApplication: null,
      confirmationModalHide: false,
      changes: [],
      selectedAllStatusApplications: false,
      isChangeStatusLoading: false,
      hasError: false,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const {
      fetchApplicationsByStatus,
      updateApplicationStatusById,
      updateApplicationStatuses,
    } = useApplicationsList(root)

    return {
      fetchApplicationsByStatus,
      updateApplicationStatusById,
      updateApplicationStatuses,
    }
  },
  computed: {
    ...mapGetters({
      selectedViewTags: 'app-applications/getSelectedViewTags',
      searchQuery: 'app-applications/getSearchQuery',
      filterDataStatus: 'app-applications/getFilterDataStatus',
      filterDataPaymentStatus: 'app-applications/getFilterDataPaymentStatus',
      filterDataStep: 'app-applications/getFilterDataStep',
      filterDataGrade: 'app-applications/getFilterDataGrade',
      allApplicationsByStatuses: 'app-applications/getAllApplicationsByStatuses',
      programId: 'verticalMenu/getDefaultProgram',
    }),
    markedElements: {
      get() {
        return store.getters['app-applications/getMarkedElements']
      },
      set(val) {
        store.commit('app-applications/SET_MARKED_ELEMENTS', val)
      },
    },
  },
  watch: {
    programId() {
      this.refetchApplications()
    },
    selectedViewTags() {
      this.refetchApplications()
    },
    searchQuery() {
      this.refetchApplications()
    },
    filterDataPaymentStatus() {
      this.refetchApplications()
    },
    filterDataStep() {
      this.refetchApplications()
    },
    filterDataGrade() {
      this.refetchApplications()
    },
  },
  mounted() {
    this.setActivePage()
    this.fetchApplications()
    this.setIntervalTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async fetchApplications() {
      try {
        this.isLoading = true

        const queryParams = {
          sortBy: store.getters['app-applications/getStorageSortBy'],
          sortDesc: store.getters['app-applications/getStorageIsSortByDesc'],
          withoutDraft: true,
          programId: this.programId,
          userName: this.searchQuery ? this.searchQuery : null,
          userRole: this.filterDataUserType ? this.filterDataUserType : null,
          paymentStatus: this.filterDataPaymentStatus ? this.filterDataPaymentStatus : null,
          application_step_id: this.filterDataStep ? this.filterDataStep : null,
          grade: this.filterDataGrade ? this.filterDataGrade : null,
          status_id: this.statusId,
          perPage: this.perPage,
          page: this.page,
        }

        const response = await this.fetchApplicationsByStatus(queryParams)

        if (response.status === 200) {
          this.applications.push(...response.data.data)
          if (response.data.data.length) {
            this.$store.commit('app-applications/UPDATE_ALL_APPLICATIONS_BY_STATUSES', response.data.data)
          }
          this.lastPage = response.data.meta.last_page

          const query = { ...this.$route.query }
          query[this.statusId] = response.data.meta.current_page
          this.$router.replace({ query }).catch(() => {})

          this.page += 1
        } else {
          this.hasError = true
        }
      } catch {
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    async fetchPrevApplications() {
      try {
        this.isLoading = true
        this.currentPageOnRefresh -= 1

        const queryParams = {
          sortBy: 'created_at',
          sortDesc: true,
          withoutDraft: true,
          programId: this.programId,
          userName: this.searchQuery ? this.searchQuery : null,
          userRole: this.filterDataUserType ? this.filterDataUserType : null,
          paymentStatus: this.filterDataPaymentStatus ? this.filterDataPaymentStatus : null,
          application_step_id: this.filterDataStep ? this.filterDataStep : null,
          grade: this.filterDataGrade ? this.filterDataGrade : null,
          status_id: this.statusId,
          perPage: this.perPage,
          page: this.currentPageOnRefresh,
        }

        const response = await this.fetchApplicationsByStatus(queryParams)
        if (response.status === 200) {
          this.applications.unshift(...response.data.data)
          this.$store.commit('app-applications/UPDATE_ALL_APPLICATIONS_BY_STATUSES', response.data.data)
        } else {
          this.hasError = true
        }
      } catch {
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    setActivePage() {
      const query = { ...this.$route.query }
      this.page = query[this.statusId] ? +query[this.statusId] : 1
      this.currentPageOnRefresh = query[this.statusId] ? +query[this.statusId] : 1
    },
    async checkMove(event) {
      const oldStatusId = event.from.id
      const newStatusId = event.to.id

      if (oldStatusId !== newStatusId) {
        this.newStatusId = newStatusId
        this.draggedApplication = event.draggedContext.element
      } else {
        this.newStatusId = null
      }
    },
    async dispatchStatusChanged() {
      if (this.newStatusId) {
        if (this.markedElements.length) {
          this.confirmationModalHide = true
        } else if (this.draggedApplication.is_locked_status) {
          await this.saveChosenStatus()
        } else {
          this.confirmationModalHide = true
        }
      }
    },
    async onRemove(e) {
      if (this.markedElements.length) {
        this.markedElements.forEach(appId => {
          const appIndex = this.applications.findIndex(app => app.id === appId)
          this.applications.splice(appIndex, 1)
        })
      } else {
        const appIndex = this.applications.findIndex(app => app.id === e.item.id)
        this.applications.splice(appIndex, 1)
      }
    },
    onAdd(e) {
      if (this.markedElements.length) {
        const newApps = this.allApplicationsByStatuses.filter(app => this.markedElements.includes(app.id))
        this.applications.splice(e.newIndex, 0, ...newApps)
      } else {
        const app = this.allApplicationsByStatuses.find(app => app.id === e.item.id)
        this.applications.splice(e.newIndex, 0, app)
      }
    },
    async updApplicationStatuses(withoutCharging = null) {
      try {
        this.isChangeStatusLoading = true

        const queryParams = {
          status_id: this.newStatusId,
          without_charging: !!withoutCharging,
        }
        if (this.markedElements.length) {
          queryParams.ids = this.markedElements
        } else {
          queryParams.ids = [this.draggedApplication.id]
        }

        await this.updateApplicationStatuses(queryParams)
        this.updateLockedStatus()
        this.emptyData()
      } catch {
        this.confirmationModalHide = false
      } finally {
        this.isChangeStatusLoading = false
      }
    },
    async saveChosenStatus() {
      if (this.newStatusId && (this.markedElements.length || this.draggedApplication)) {
        await this.updApplicationStatuses()
      }
    },
    async discardChanges() {
      await this.emptyData()
    },
    async emptyData() {
      this.confirmationModalHide = false
      this.newStatusId = null
      this.draggedApplication = null
      this.changes = []
      this.markedElements = []
    },
    updateLockedStatus() {
      if (this.markedElements.length) {
        this.allApplicationsByStatuses
          .filter(app => this.markedElements.includes(app.id))
          .forEach(app => {
            // eslint-disable-next-line no-param-reassign
            app.is_locked_status = true
          })
      } else {
        this.draggedApplication.is_locked_status = true
      }
    },
    markElementsOnStatus(val) {
      const elements = this.markedElements
      if (val) {
        this.applications.forEach(application => {
          const index = elements.indexOf(application.id)
          if (index < 0) {
            elements.push(application.id)
          }
        })
      } else {
        this.applications.forEach(application => {
          const index = elements.indexOf(application.id)
          if (index >= 0) {
            elements.splice(index, 1)
          }
        })
      }
      this.markedElements = elements
    },
    infiniteScrollHandler() {
      const { clientHeight } = this.$refs.statusCardBody
      const { scrollHeight } = this.$refs.statusCardBody
      const { scrollTop } = this.$refs.statusCardBody
      const bottomOff = scrollHeight - clientHeight - scrollTop

      if (bottomOff < 100 && !this.isLoading && !this.hasError && this.lastPage >= this.page) {
        this.fetchApplications()
      }
    },
    setIntervalTimer() {
      this.timer = setInterval(() => {
        this.infiniteScrollHandler()
      }, 300)
    },
    resetData() {
      this.applications = []
      this.page = 1
      this.currentPageOnRefresh = 1
      this.lastPage = 1
      this.hasError = false
    },
    refetchApplications() {
      this.resetData()
      this.fetchApplications()
    },
  },
}
</script>

<style lang="scss" scoped>
.status-card {
  align-self: start;
  background: #FBFBFC;
  border: 1px solid #D8D6DE;
  border-radius: 8px;
  width: 270px;
  flex-basis: 270px;
  flex-shrink: 0;
  margin: 0 10px 20px;
  &-body {
    padding-top: 0;
    padding-bottom: 20px;
    height: 60vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-button {
        height: 0px;
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #D8D6DE;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #7367F0;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: darken(#7367F0, 5%);
      }
  }
}
.load-prev {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}
</style>
